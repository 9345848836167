import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 20px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 300px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 20px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
  color: var(--primary-text);
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100px;
  @media (min-width: 1024px) {
    width: 200px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledIcon = styled.img`
  width: 30px;
  @media (min-width: 1024px) {
    width: 40px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
  margin-right: 20px;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const MyTitle = styled.p`
-webkit-text-stroke-width: 2.0px;
  -webkit-text-stroke-color: black;
`;

export const MyDiv = styled.div`
-webkit-text-stroke-width: 2.0px;
  -webkit-text-stroke-color: black;
`;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click the button below to mint your FroXx.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Please wait while your ${CONFIG.NFT_NAME} is being Minted!`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `YES it worked! The ${CONFIG.NFT_NAME} is now yours! You can view it on Opensea.io.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container flex={1} ai={"center"} style={{ padding: 0, backgroundColor: "var(--primary)" }} image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}>
      <ResponsiveWrapper flex={2} style={{ paddingBottom: 10, paddingTop: 10,marginTop: 0, background: "#000000" }}>
       <MyDiv style={{ marginLeft: "auto"}}>
        <a href={CONFIG.TWITTER_LINK} target={"_blank"} title={"Twitter"}><StyledIcon alt={"logo"} src={"/config/images/twitter.svg"} /></a>
        <a href={CONFIG.DISCORD_LINK} target={"_blank"} title={"Discord"}><StyledIcon style={{transform: "scale(2.0)"}} alt={"logo"} src={"/config/images/discord.svg"} /></a>
        <a href={CONFIG.MARKETPLACE_LINK} title={"Opensea"}><StyledIcon alt={"logo"} src={"/config/images/opensea.svg"} /></a>
      </MyDiv>
      </ResponsiveWrapper>
        
        
        
      <s.TextTitle style={{textAlign: "center",fontSize: 60,fontWeight: "bold",color: "var(--accent-text)",}}>
            <MyTitle>FroXx - on the blockchain</MyTitle>
            </s.TextTitle>       
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={2} jc={"center"} ai={"center"} style={{backgroundColor: "var(--accent)",padding: 24,borderRadius: 24,border: "3px solid var(--secondary)",boxShadow: "0px 5px 11px 2px rgba(200,255,200,.05)",}}>

            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center",color: "var(--accent-text)",}}>
                  1 {CONFIG.NFT_NAME} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center",color: "var(--accent-text)",}}>
                  Excluding gas fees (*)
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{textAlign: "center",color: "var(--accent-text)",}}>
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData();}}>
                      CONNECT WALLET
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{textAlign: "center",color: "red",}}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription style={{textAlign: "center", color: "lightgreen",}}>
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(); getData(); }}>
                        {claimingNft ? "BUSY WORKING" : "BUY A FROXX"}
                      </StyledButton>
                    </s.Container>                    
                  </>
                )}<s.SpacerLarge /><s.SpacerLarge />
                <s.TextTitle style={{textAlign: "center",fontSize: 40,fontWeight: "bold",color: "var(--accent-text)",}}>
                <MyTitle>Important about the mint phase!</MyTitle>                  
                </s.TextTitle>
                <s.TextTitle style={{textAlign: "center",fontSize: 20,fontWeight: "bold",color: "yellow", }}>
                0.009 ETH will be Airdropped back to a random FroXx holder on every mint!
                </s.TextTitle>
                <s.TextDescription style={{textAlign: "center",color: "var(--accent-text)",}}>
                Early adapter feature: To try help attract early adopters, I have decided to experiment with a "mint-raffle" feature during the mint phase of FroXx.
                <s.SpacerMedium />

                All FroXx holders will during the mint phase automaticly participating in a raffle every 
                time a new FroXx gets minted forward. (30% of 0.03ETH = 0.009ETH will be airdropped to a
                random FroXx wallet. - All airdrops can be found on <a href={CONFIG.RAFFLE_LINK} target={"_blank"}>Etherscan</a>)<s.SpacerMedium />

                <ul>
                  <li>1 FroXx will count as 1 ticket in the raffles.</li>
                  <li>30% of the mintprice will go back to the holders.</li>
                  <li>20% of the sales will be held back as a community wallet.</li>
                  <li>50% will remain with the team to continue onward with more OnChainLAB projects.</li>
                  <li>= 100% is reinvested back into the NFT Space</li>
                </ul>
                </s.TextDescription>
              </>
            )}
            <s.SpacerMedium />
            <s.TextTitle style={{ textAlign: "center", fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
            <MyTitle>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</MyTitle>
            </s.TextTitle>
            <s.TextDescription style={{textAlign: "center",color: "var(--primary-text)",}}>
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} style={{ transform: "scaleX(-1)" }} />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={2} jc={"center"} ai={"center"} style={{backgroundColor: "var(--accent)",padding: 24,borderRadius: 24,border: "3px solid var(--secondary)",boxShadow: "0px 5px 11px 2px rgba(200,255,200,.05)"}}>
            <s.TextTitle style={{textAlign: "center",fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
            <MyTitle>Why FroXx?</MyTitle>
            </s.TextTitle> 
            <s.TextDescription style={{textAlign: "center",color: "var(--accent-text)",}}>
              FroXx is developed by SB from OnChainLAB - A new Blockchain Company 2021.<br />SB have been behind several large Web1 and Web2 websites and communities since 1998. Now the time has come to Web3!
              <s.SpacerMedium />
              FroXx is the first "official" project under the OnChainLAB tag - I consider this project my Genesis project in the NFT space. This means FroXx holders in the future 
              can expect to have VIP access to whitelists or even free mints to projects I will produce and/or from projects OnChainLAB collab with.
              <s.SpacerMedium />
              I consider this an experimental project into a some unknown things for me<br /> (100% onchain datastorage and a raffle function based on randomness in a randomless enviroment).<s.SpacerMedium />
              I URGE you to do your own research before jumping into any NFT - avoid FOMO, but investigate what you about to invest into. <s.SpacerMedium />
              The FroXx in itself will not deliver any specific value, other than the actual art and a chance to get whitelist to future projects and possible airdrops from the team. AND the chance to be part of a new community :)
              I'd rather be straight forward right away and not deliver any long roadmap that could potentially say anything to try lure you into buying a FroXx. Though I can promise that I will never forget who was behind this first NFT project as buyers/holders.<br />
              The code behind IS experimental and bugs can happen. You are more than welcome to dig through the smart contract on <a href={CONFIG.CONTRACT_LINK} target={"_blank"}>Etherscan</a> as I have opensourced the code to the public.
              <s.SpacerLarge />
              <s.TextTitle style={{textAlign: "center",fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
              <MyTitle>What makes FroXx different?</MyTitle>
              </s.TextTitle> 
              FroXx differs from most other NFT projects by a couple of things.
              <s.SpacerXSmall />
              1) Each FroXx in its entirety (image + metadata) is stored 100% on the Ethereum Blockchain.
              <br />
              2) Each FroXx is first created the second someone Mints it. Based on random parameters.
              <br />
              3) Each FroXx will act as a ticket to automaticly enter an ETH raffle on each FroXx mint forward.
              <br />
              4) The image is in SVG format. Scalable Vector Graphics.
              <br />
              5) No FroXx can ever be minted for free, even by the Owner of the contract.
            
            <s.SpacerLarge />
              <s.TextTitle style={{textAlign: "center",fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
              <MyTitle>What is to come?</MyTitle>
              </s.TextTitle> 
              FroXx is just my first official step into the NFT space. After this release of the smart contract and website, 
              I will push marketing a bit to hopefully onboard some members to the new community.<br />
              I will be active on Twitter and have a Discord channel ready to support anyone who needs it.<s.SpacerXSmall />
              The next NFT project is already in the works - It will again be purely onchain, but I will take a more artistic approach compared to FroXx.
              <s.TextTitle style={{textAlign: "center",fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
              <MyTitle>Can I help in some way?</MyTitle>
              </s.TextTitle>
              Indeed you can! There is only so much I can do alone.<br />I
              will be looking for help to moderate discord / marketing managers / artists and possible also more developers
              But make your entrance through the Discord and let's get to know each other a bit first :)<br />
              I would love to get your ideas and possible work with you on a future project.
            </s.TextDescription>

          </s.Container> 
          <s.SpacerLarge />
         <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} style={{ transform: "scaleX(-1)", }} />
         </s.Container>
        </ResponsiveWrapper>

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%", }}>
          <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)",}}>
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and with the correct wallet. <br />
            Please note: Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{textAlign: "center",color: "var(--primary-text)",}}>
            (*) We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to successfully mint your NFT. We recommend that you don't lower the gas limit.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}
export default App;
